import { Injectable, inject } from '@angular/core';
import { WeatherApiService } from '@core/api/weather-api.service';
import { DateNow, WeatherDateFormating } from '@core/constants/time.defaults';
import { NotificationsService } from '@core/services/helpers/notifications.service';
import {
  AppInitializeAction,
  ToDateSelector,
  SetSystemDatesAction,
  ToggleWindyLayerAction,
  WindyLayerSelector,
  RealtimeIsActiveSelector,
  SetRealtimeFlagAction,
  WindyTypes,
} from '@dashboard-store';
import {
  CancelWeatherLoadingAction,
  GetWeatherDataAction,
  GetWeatherDataErrorAction,
  GetWeatherDataSuccessAction,
  StartWeatherTimerAction,
  StopWeatherTimerAction,
} from '@dashboard/dashboard-store/actions/weather.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { WeatherDataService } from '@widgets/map-widget/services/weather-data.service';
import {
  catchError,
  EMPTY,
  map,
  of,
  switchMap,
  takeUntil,
  withLatestFrom,
} from 'rxjs';

@Injectable()
export class WeatherEffects {
  private _weatherApi = inject(WeatherApiService);
  private _weather = inject(WeatherDataService);
  private _notif = inject(NotificationsService);
  private actions$ = inject(Actions);
  private _store = inject(Store);

  private latestApiMeta: Record<WindyTypes, string> = {
    wind: '',
    waves: '',
    currents: '',
  };

  InitializeMapEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppInitializeAction),
      switchMap(() =>
        of(
          GetWeatherDataAction({
            windType: 'ALL',
            source: 'InitializeMapEffect',
          })
        )
      )
    );
  });

  //Load weather data but if while getting the data from api date change we need to stop basicaly cancel it.

  GetWeatherApiData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GetWeatherDataAction),
      withLatestFrom(
        this._store.select(ToDateSelector),
        this._store.select(RealtimeIsActiveSelector)
      ),
      switchMap(([{ windType }, toDate, isRealTime]) => {
        const currentWeatherTimestamp = WeatherDateFormating(toDate);

        /**
         * The next date variable must be confusing but.... when we are in real time the "toDate" property from state
         * is not the current timestamp since we are not updating all the time. So we need to get the current timestamp
         */
        const _weatherDateApi = isRealTime ? DateNow() : toDate;

        /**
         * We keep the previous weather timestamp. if the current timestamp is the same as the previous one we return empty.
         * We check if is real time because when it is real time this is always true an laways the request is getting canceled.
         */

        if (!isRealTime) {
          if (
            !windType ||
            (windType === 'ALL' &&
              this.latestApiMeta.wind === currentWeatherTimestamp)
          )
            return of(CancelWeatherLoadingAction());

          if (this.latestApiMeta[windType] === currentWeatherTimestamp)
            return of(CancelWeatherLoadingAction());
        }
        return this._weatherApi.getWeatherData(_weatherDateApi, windType).pipe(
          switchMap(({ wind, waves }) => {
            //Every time the requrest completes one or more will have data. if there are data. then we update the latestApiMeta
            if (wind)
              this.latestApiMeta.wind = WeatherDateFormating(_weatherDateApi);
            if (waves)
              this.latestApiMeta.waves = WeatherDateFormating(_weatherDateApi);

            return of(GetWeatherDataSuccessAction({ wind, waves }));
          }),
          catchError((error) => of(GetWeatherDataErrorAction(error))),
          // Cancel the request and dispatch CancelWeatherDataLoadingAction if SetSystemDatesAction is triggered
          takeUntil(this.actions$.pipe(ofType(SetSystemDatesAction)))
        );
      })
    );
  });
  CancelWeatherDataOnSystemDateChange$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SetSystemDatesAction),
      map(() => CancelWeatherLoadingAction()) // Dispatch the cancel action to reset loading
    );
  });
  SetWeatherData$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(GetWeatherDataSuccessAction),
        withLatestFrom(this._store.select(WindyLayerSelector)),
        map(([{ wind, waves }, windyType]) => {
          wind && this._weather.setWindData(wind);
          waves && this._weather.setWavesData(waves);

          if (windyType) {
            this._weather.showWindyLayer(windyType);
          }
        })
      );
    },
    { dispatch: false }
  );

  InitializeWeatherTimer$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AppInitializeAction, StartWeatherTimerAction),
        map(() => {
          this._weather.InitWeatherDataTimer(this.WeatherCallBack());
        })
      );
    },
    { dispatch: false }
  );

  StopWeatherTimer$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(StopWeatherTimerAction, SetRealtimeFlagAction),
        map((action) => {
          if (action.type === StopWeatherTimerAction.type) {
            this._weather.StopWeatherDataTimer();
            return;
          }
          if (action.type === SetRealtimeFlagAction.type) {
            if (action.enableRealtime) return;
            this._weather.StopWeatherDataTimer();
          }
        })
      );
    },
    { dispatch: false }
  );

  //if toDate changed and is real time then we need to start the weather
  StartTimerWithRealTime$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SetRealtimeFlagAction),
      switchMap(({ enableRealtime }) => {
        if (enableRealtime) {
          //We do the api call and we start the timer
          this._weather.InitWeatherDataTimer(this.WeatherCallBack());
          return of(
            GetWeatherDataAction({
              windType: 'ALL',
              source: 'StartTimerWithRealTime',
            })
          );
        }
        return EMPTY;
      })
    );
  });

  //if toDate changed then we need to clear the data and toggle off the wind layers
  clearWeatherData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SetSystemDatesAction),
      switchMap(({ toDate }) => {
        const toDateExists = !!toDate;

        if (toDateExists) {
          this._weather.hideWindyLayer();
          this._weather.clearWeatherData();
          this._weather.StopWeatherDataTimer();
        }

        return of(ToggleWindyLayerAction({ windyType: null }));
      })
    );
  });

  toggleWindLayer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ToggleWindyLayerAction),
      withLatestFrom(this._store.select(RealtimeIsActiveSelector)),
      switchMap(([{ windyType }, isRealTime]) => {
        this._weather.showWindyLayer(windyType);

        if (isRealTime || !windyType) return EMPTY;
        return of(
          GetWeatherDataAction({
            windType: windyType,
            source: 'toggleWindLayer',
          })
        );
      })
    );
  });

  private WeatherCallBack() {
    return () => {
      this._store.dispatch(
        GetWeatherDataAction({
          windType: 'ALL',
          source: 'Timer Weather CB',
        })
      );
    };
  }
}
