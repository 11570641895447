import { inject, Injectable } from '@angular/core';
import { TimestampIsInCurrentMinute } from '@core/constants/time.defaults';
import {
  SetRealtimeFlagAction,
  SetSystemDatesAction,
} from '@dashboard/dashboard-store/actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of, switchMap } from 'rxjs';
@Injectable()
export class WsEffects {
  private actions$ = inject(Actions);
  private _store = inject(Store);

  HandleRealtimeFlag$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SetSystemDatesAction),
      switchMap(({ toDate }) => {
        const isRealTime = TimestampIsInCurrentMinute(toDate);
        return of(SetRealtimeFlagAction({ enableRealtime: isRealTime }));
      })
    );
  });
}
