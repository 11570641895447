import { Injectable } from '@angular/core';
import { ColorGrouping } from '@dashboard/dashboard-library/helpers/map-utils/trace-helpers';
import { Position } from '@dashboard/dashboard-library/types/map.types';
import L from 'leaflet';
import { Map } from 'leaflet';

@Injectable({
  providedIn: 'root',
})
export class CanvasTraceService {
  private LastTraceData: {
    points: Position[];
    colorChangeIndexGroups: ColorGrouping[];
  } = null;

  private TraceCanvas: HTMLCanvasElement;
  private _map: Map;

  constructor() {}

  public get map() {
    return this._map;
  }

  public set map(map: Map) {
    this._map = map;
  }

  drawingLines(data: {
    points: Position[];
    colorChangeIndexGroups: ColorGrouping[];
  }) {
    this.LastTraceData = data;
    const canvas = this.TraceCanvas;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    const { colorChangeIndexGroups, points } = data;

    let PrevPointB: Position;
    colorChangeIndexGroups.forEach(([minInex, maxIndex, color]) => {
      const pointA = PrevPointB || points[minInex];

      const pointB = points[maxIndex] || points[maxIndex + 1];

      PrevPointB = pointB;

      const isDashed = color == null;
      this.drawLine(
        ctx,
        [pointA, pointB],
        isDashed ? 'white' : color,
        isDashed
      );
    });
  }

  drawLine(
    ctx: CanvasRenderingContext2D,
    points: [[number, number], [number, number]],
    color: string,
    dashed: boolean
  ) {
    // Map latitude and longitude to canvas points
    try {
      const ProjectedPoints = points.map((latLng) =>
        this._map.latLngToContainerPoint(latLng)
      );

      // Draw the red line on the canvas
      ctx.beginPath();

      if (dashed) {
        ctx.setLineDash([6, 6]);
        ctx.lineWidth = 1;
      } else {
        ctx.setLineDash([0, 0]);
        ctx.lineWidth = 4;
      }

      ctx.strokeStyle = color;
      ctx.moveTo(ProjectedPoints[0].x, ProjectedPoints[0].y);
      ProjectedPoints.forEach((point) => ctx.lineTo(point.x, point.y));
      ctx.stroke();
    } catch (error) {
      console.warn('Projecting points:', points);
    }
  }
  // Reposition canvas on map move
  updateCanvasPosition() {
    const map = this._map;
    const canvas = this.TraceCanvas;

    if (!map || !canvas) return;

    // Get the top-left corner of the current map view in pixels
    const topLeft = map.getBounds().getNorthWest();
    const offset = map.latLngToLayerPoint(topLeft);

    // Apply the offset to position the canvas correctly
    L.DomUtil.setPosition(canvas, offset);
    this.updateCanvas(); // Redraw the canvas content after moving it
  }

  addCanvasToMap(map: Map) {
    const canvas = L.DomUtil.create('canvas', 'leaflet-overlay-canvas');
    canvas.classList.add('leaflet-canvas');
    // Append the canvas to the overlay pane
    const overlayPane = map.getPane('overlayPane');
    overlayPane.appendChild(canvas);

    // Set the canvas size to match the map container
    canvas.width = map.getSize().x;
    canvas.height = map.getSize().y;
    this.TraceCanvas = canvas;
    map.on('moveend', () => this.updateCanvasPosition());
    map.on('zoomstart', () => {
      this.clearCanvas();
    });
    map.on('zoomend', () => {
      this.updateCanvasPosition();
    });
  }
  updateCanvas() {
    if (this.LastTraceData) {
      // Redraw lines using the last known data
      this.drawingLines(this.LastTraceData);
    }
  }

  public removeTrace() {
    this.LastTraceData = null;
    this.clearCanvas();
  }

  clearCanvas() {
    const canvas = this.TraceCanvas;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  }

  antimeridianAlgorithm(coords: Position[]) {
    let lastValidCoord: Position = null;

    for (let i = 1; i < coords.length; i++) {
      try {
        const currentCoord = coords[i];
        const prev = coords[i - 1];

        if (prev) lastValidCoord = prev;

        if (!currentCoord) continue;

        const deltaLng = lastValidCoord[1] - currentCoord[1];

        if (Math.abs(deltaLng) > 180) {
          // Adjust for crossing the anti-meridian
          if (deltaLng > 0) {
            coords[i][1] = currentCoord[1] + 360;
          } else {
            coords[i][1] = currentCoord[1] - 360;
          }
        }
      } catch (error) {
        console.error('Error in antimeridianAlgorithm:', { lastValidCoord });
      }
    }
    return coords;
  }
}
