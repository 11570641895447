import { Injectable, inject } from '@angular/core';
import { ArrayToObject } from '@core/utils/arrays';
import {
  ExtraStateIds,
  ExtraStateIdsKeys,
  IVessel,
  MapTagLabelToId,
  SystemTags,
} from '@dashboard/models';
import {
  DashboardData,
  DashboardVarValue,
  DashboardVesselData,
  InitDashboardStateAction,
  SetMapLayersConfigAction,
  SetTraceConfigAction,
  SetVariablesDataAction,
  SetVariablesPayloadType,
  SetVesselsAction,
} from '@dashboard-store';
import { Store } from '@ngrx/store';
import { IDashboardResponse } from '@dashboard/models/dashboard/dashboard.types';
import { WidgetMapConfiguration } from '@widgets/widget.model';
import {
  StateApiService,
  VesselStateResponse,
} from '@core/api/state-api.service';
import {
  combineLatest,
  concatMap,
  forkJoin,
  from,
  map,
  of,
  switchMap,
  tap,
  toArray,
} from 'rxjs';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import padWithZeros from '@core/utils/padWithZeros';
import { TransformIdToTag } from '@dashboard/tags-to-ids.transformer';

@Injectable()
export class StoreInitService {
  private Notify = inject(NzNotificationService);
  private _store = inject(Store);
  private _stateApi = inject(StateApiService);

  public StoreSetup(vessels: IVessel[]): void {
    const StoreDataState = this.initVesselsDataState(vessels);

    const resultObject = ArrayToObject(vessels, '_id');

    this._store.dispatch(SetVesselsAction({ vessels: resultObject }));

    this._store.dispatch(
      InitDashboardStateAction({ varState: StoreDataState })
    );
  }

  private initVesselsDataState(vessels: IVessel[]): DashboardData {
    const obj = {} as DashboardData;
    vessels.map((v) => {
      obj[v.slug] = this.GenerateVariableNamesForVessel();
    });
    return obj;
  }

  private GenerateVariableNamesForVessel(): DashboardVesselData {
    const obj = {} as DashboardVesselData;
    const tagNames = [
      ...Object.keys(MapTagLabelToId),
      ...Object.keys(ExtraStateIdsKeys),
    ] as Array<SystemTags | ExtraStateIds>;

    tagNames.forEach((tagName) => {
      const defaultValue: Required<DashboardVarValue> = {
        formattedValue: '',
        rawValue: '',
        timestamp: 0,
        value: '',
        variableId: '',
        values: [],
        notInSync: false,
      };

      obj[tagName] = defaultValue;
    });
    return obj;
  }

  public InitalizeMapLayers(config: IDashboardResponse) {
    const { layers } = this.GetMapConfig(config);

    this._store.dispatch(
      SetMapLayersConfigAction({
        baseMaplayers: layers.baseMaplayers,
        overlayLayers: layers.overlayLayers,
      })
    );
  }
  public InitializeTrace(config: IDashboardResponse) {
    try {
      const {
        trace: { options: traceConfig },
      } = this.GetMapConfig(config);
      this._store.dispatch(SetTraceConfigAction({ traceConfig }));
    } catch (error) {
      console.error('Wrong config for trace', error);
    }
  }
  private GetMapConfig(config: IDashboardResponse) {
    const configMap = Object.values(config.widgets).filter(
      (widget) => widget.type === 'MAP'
    ) as WidgetMapConfiguration[];

    return configMap[0];
  }

  public GetInitalVesselData(vessels: IVessel[], date?: string) {
    return combineLatest(
      vessels.map((v) => this._stateApi.getVesselState(v.slug, date))
    ).pipe(
      //If there is an error above then the array have null values. We filter them out
      map((data) => data.filter((d) => !!d)),
      map((data) => {
        try {
          return data.map(this.VesselStateDataToActionData).flat();
        } catch (error) {
          console.error('Error on state requesting', error);
        }
      })
    );
  }

  public getInitialVesselsData(date?: string) {
    return this._stateApi
      .getAllVesselsState(date)
      .pipe(map((data) => data.map(this.VesselStateDataToActionData).flat()));
  }
  private GetIntialVesselsInChunks(vessels: IVessel[], date?: string) {
    return of(vessels).pipe(
      switchMap((vessels) =>
        from(this.chunkArray(vessels, 4))
          .pipe(
            concatMap((vessels) => {
              const v = vessels.map((v) =>
                this._stateApi.getVesselState(v.slug, date)
              );
              return forkJoin(v);
            }),
            tap((data) => {
              const variableMeta = data
                .map(this.VesselStateDataToActionData)
                .flat();
              this._store.dispatch(
                SetVariablesDataAction({
                  variableMeta,
                  source: 'API',
                })
              );
            })
          )
          .pipe(toArray())
      )
    );

    // return of(vessels).pipe(
    //   switchMap((vessels) =>
    //     from(this.chunkArray(vessels, 6))
    //       .pipe(
    //         mergeMap((vesselsChunked) =>
    //           from(vesselsChunked).pipe(
    //             concatMap((v) =>
    //               this._stateApi.getVesselState(v.slug, selectedDate)
    //             ),
    //             tap((data) => {
    //               console.log('data1', data);
    //               this._store.dispatch(
    //                 SetVariablesDataAction({
    //                   variableMeta: this.VesselStateDataToActionData(data),
    //                 })
    //               );
    //             })
    //           )
    //         ),
    //         tap((data) => console.log('data2', data))
    //       )
    //       .pipe(
    //         tap((data) => console.log('data3', data)),
    //         toArray()
    //       )
    //   )
    // );
  }

  chunkArray<T>(arr: T[], size: number): T[][] {
    const result = [];
    for (let i = 0; i < arr.length; i += size) {
      result.push(arr.slice(i, size + i));
    }
    return result;
  }

  public VesselStateDataToActionData(vessel: VesselStateResponse) {
    const VesselKeys = Object.keys(vessel);
    if (VesselKeys.length === 0) return [];

    const { imo, states } = vessel;
    const VesselVariableData: SetVariablesPayloadType[] = [];

    //The response return an array of states. But when we gather data for map the length will be laways one
    const { tags, stateTimestamp } = states[0];

    //we manually add the vessel latest timestamp.
    const latestTimestamp: SetVariablesPayloadType = [
      padWithZeros(imo, 8),
      'latest_timestamp',
      {
        notInSync: false,
        variableId: 'latest_timestamp',
        timestamp: stateTimestamp,
        formattedValue: `${stateTimestamp}`,
      },
    ];

    VesselVariableData.push(latestTimestamp);

    for (const key in tags) {
      try {
        const dat: SetVariablesPayloadType = [
          padWithZeros(imo, 8),
          TransformIdToTag(key),
          { ...tags[key], variableId: 'removethis' } as any,
        ];

        VesselVariableData.push(dat);
      } catch (error) {
        console.error('Error on state requesting', error);
      }
    }
    return VesselVariableData;
  }
}
