<div class="w-100 flex justify-center items-center p-5 flex-col">
  <h1 class="text-xl">Application Changelog</h1>
  <div id="content">
    @if (NoData) {
      <p>No Data</p>
    } @else {
      <p [innerHTML]="changeLogData" style="white-space: pre"></p>
    }
  </div>
</div>
