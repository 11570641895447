import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { EnvVarsService } from 'src/app/env-vars.service';

export interface ICalcPayload {
  fuel_type: number;
  dwt: number;
}
@Injectable({
  providedIn: 'root',
})
export class RouteOptimizationApiService {
  private API_URL = this.environment.getEnvKey('ROUTE_OPT_URL');
  private url = `${this.API_URL}`;

  private messageSource = new BehaviorSubject<string>('');
  currentMessage = this.messageSource.asObservable();

  changeMessage(message: string) {
    this.messageSource.next(message);
  }

  constructor(
    private _http: HttpClient,
    private environment: EnvVarsService
  ) {}

  public RouteOptimization(
    vesselId: string,
    payload: TCoursePlaningPayload
  ): Observable<TCoursePlaningReponse> {
    return this._http.post<TCoursePlaningReponse>(
      `${this.url}/voyage-planner/${vesselId}`,
      payload
    );
  }
}

//! NEW IMPLEMENTATIOMN TYPES WIP WILL CHANGE AS BACKEND IS NOT FINISHED

export type Coordinates = [number, number];

export type TCoursePlaningReponse = {
  dt: string[];
  foc: number[];
  segments: Coordinates[];
  speed: number[];
  summary: TSummary[];
  ws: number[];
};

export type TCoursePlaningPayload = {
  route: Coordinates[];
  starttime?: number;
  speed?: number;
  duration_h?: number;
  loading?: TCoursePlanningPayloadLoading;
  reference: TCoursePlanningPayloadReference;
  UserSpeedReference: 'id';
  FuelType: TCoursePlanningPayloadFuelType;
  weather: TCoursePlanningPayloadWeather;
  draft?: number;
};

export type TCoursePlanningPayloadWeather =
  | 'as in reference'
  | 'actual'
  | 'BF4'
  | 'BF5'
  | 'BF6';

export type TCoursePlanningPayloadFuelType = 1 | 2 | 3 | 4 | 5 | 6;

export type TCoursePlanningPayloadLoading = 0 | 1 | 2 | 3 | 4 | 5;

export type TCoursePlanningPayloadReference =
  | 'EstablishedSpeedRef'
  | 'SeaTrials'
  | 'UserSpeedReferences';

export type TSummary =
  | ['total_dist', number]
  | ['constrain_type', string]
  | ['speed', number]
  | ['duration', number]
  | ['power', number]
  | ['bsfc', number]
  | ['total_fuel', number];
