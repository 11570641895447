import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IGNORED_STATUSES, IGNORED_VALUE } from '@core/api/common.api';
import { NotificationsService } from '@core/services/helpers/notifications.service';
import { catchError, map, throwError } from 'rxjs';

type ApiNotificationMessages = {
  error: string;
  success: string;
};

const DEFAULT_NOT_OBJ: ApiNotificationMessages = {
  error: null,
  success: null,
};

@Injectable({
  providedIn: 'root',
})
export class CommonApiService {
  constructor(
    private _http: HttpClient,
    private notif: NotificationsService
  ) {}

  public get<T>(
    path: string,
    notifications: ApiNotificationMessages = DEFAULT_NOT_OBJ,
    options: any = {}
  ) {
    return this._http
      .get<T>(path, {
        ...options,
        context: new HttpContext().set(IGNORED_STATUSES, [IGNORED_VALUE]),
      })
      .pipe(
        map((response) => {
          this.ApiSuccessCB(notifications);
          return response as T;
        }),

        catchError((error) => this.ApiErrorCB(error, notifications))
      );
  }

  public post<T>(
    path: string,
    body: object = {},
    notifications: ApiNotificationMessages = DEFAULT_NOT_OBJ,
    options: any = {}
  ) {
    return this._http
      .post<T>(path, body, {
        ...options,
        context: new HttpContext().set(IGNORED_STATUSES, [IGNORED_VALUE]),
      })
      .pipe(
        map((response) => {
          if (notifications.success)
            this.notif.succcess('Success', notifications.success);
          return response as T;
        }),
        catchError((error) => this.ApiErrorCB(error, notifications))
      );
  }

  public delete<T>(
    path: string,
    notifications: ApiNotificationMessages = DEFAULT_NOT_OBJ,
    options: any = {}
  ) {
    return this._http
      .delete<T>(path, {
        ...options,
        context: new HttpContext().set(IGNORED_STATUSES, [IGNORED_VALUE]),
      })
      .pipe(
        map((response) => {
          this.ApiSuccessCB(notifications);
          return response as T;
        }),
        catchError((error) => this.ApiErrorCB(error, notifications))
      );
  }

  private ApiSuccessCB = (notifications: ApiNotificationMessages) => {
    if (notifications.success)
      this.notif.succcess('Success', notifications.success);
  };

  private ApiErrorCB = (error: any, notifications: ApiNotificationMessages) => {
    if (notifications.error) this.notif.succcess('error', notifications.error);
    return throwError(() => error);
  };
}
