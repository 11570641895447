import { AggregationStepExport } from '@core/models/export-tool/preset-load-save.model';
import { AggregationStepDashboard } from '@dashboard/dashboard-store';
import moment from 'moment';

const calcAggr = (
  aggStep: number,
  aggType: AggregationStepDashboard | AggregationStepExport
): number =>
  ({
    m: aggStep,
    h: aggStep * 60,
    D: aggStep * 60 * 24,
    undefined: aggStep,
  })[aggType];

export default calcAggr;

export const calcAggregationLimit = (
  limit: number,
  startDate: string | Date,
  endDate: string | Date,
  step?: AggregationStepDashboard | AggregationStepExport,
  value?: number
) => {
  const aggregationValue = step ? value : 1; // Initial aggregation value
  const aggregationStep = step ?? 'm'; // Initial aggregation unit (e.g., 'minutes' or 'hours')
  const totalDifference = moment(endDate).diff(moment(startDate), 'm');

  let numberOfSections =
    totalDifference / calcAggr(aggregationValue, aggregationStep);

  // Check if the current aggregation passes the test
  const canRequest = Math.ceil(numberOfSections) <= limit;

  // If it doesn't pass, adjust the aggregation
  let recommendedValue = aggregationValue;
  while (Math.ceil(numberOfSections) > limit) {
    recommendedValue += 1; // Dynamically adjust aggregation value
    numberOfSections = totalDifference / recommendedValue;

    // Stop if recommendedValue exceeds the total period
    if (recommendedValue > totalDifference) {
      throw new Error(
        'Aggregation value exceeds total period. Cannot reduce sections further.'
      );
    }
  }

  // Prepare the output
  const result = {
    currentSize: {
      value: aggregationValue,
      unit: aggregationStep,
    },
    canRequest,
    recommendedSize: {
      value: recommendedValue,
      unit: 'm' as AggregationStepDashboard | AggregationStepExport,
    },
  };

  console.log(result);
  return result;
};
